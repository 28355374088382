require('./bootstrap');

(function($) {

    // Select 2
    $('select.select2')
        .removeClass('select2')
        .each(function() {
            let $element = $(this);
            let $emptyOption = $element.find('option[value=""]');
            $element.select2({
                placeholder: $emptyOption.text(),
                allowClear: $emptyOption.length > 0,
                containerCssClass: ':all:',
            });
        });

    /*
     * Hacky fix for a bug in select2 with jQuery 3.6.0's new nested-focus "protection"
     * see: https://github.com/select2/select2/issues/5993
     * see: https://github.com/jquery/jquery/issues/4382
     *
     * TODO: Recheck with the select2 GH issue and remove once this is fixed on their side
     */
    jQuery(document).on('select2:open', function (e) {
        window.setTimeout(function () {
            jQuery(".select2-container--open .select2-search__field").get(0).focus();
        }, 200);
    });

})(jQuery);
